<template>
    <div>
        <a-drawer :maskClosable="false" class="serve-detail" :visible="active" @close="close" width="490px">
            <div class="wrapper">
                
                <div class="at-title">
                    
                    <template v-if="item && item.id">
                        <div class="at-title-edit"></div>
                    </template>
                    <template v-else>
                        <div class="at-title-create"></div>
                    </template>
                
                </div>
                
                <perfect-scrollbar class="sd-body" :options="{suppressScrollX:false}">
                    <a-collapse class="sd-collapse" v-model="collapseActiveKey" :defaultActiveKey="0">
                        <a-collapse-panel header="基础内容" key="1" forceRender>
                            <div class="at-item">
                                <div class="at-item-title">{{ groupName }}标题</div>
                                <div class="at-item-content">
                                    <at-input maxLength="12" v-model="el.title" placeholder="请输入不超过12字的标题"
                                              class="full-with"/>
                                    <div v-if="el.title" class="input-tips">{{ el.title.length }}/12</div>
                                </div>
                            </div>
                            
                            <div class="at-item">
                                <div class="at-item-title">副标题</div>
                                <div class="at-item-content">
                                    <at-input maxLength="30" v-model="el.sub_title"
                                              placeholder="请输入不超过30字的副标题"
                                              class="full-with"/>
                                    <div v-if="el.sub_title" class="input-tips">{{ el.sub_title.length }}/30</div>
                                </div>
                            </div>
                            
                            <div class="at-item">
                                <div class="at-item-title">封面图片</div>
                                <div class="at-item-content thumb-container">
                                    <div class="thumb-img">
                                        <upload-image v-model="el.thumb"/>
                                    </div>
                                    <div class="thumb-tips">
                                        图片格式必须为：png,jpeg,jpg；不可大于1M；<br>
                                        建议使用png格式图片，以保持最佳效果；<br>
                                        建议图片尺寸为600px*600px
                                    </div>
                                </div>
                            
                            </div>
                            
                            <div class="at-item item-two">
                                <div class="at-item-sg">
                                    <div class="at-item-title">{{ groupName }}时间（选填）</div>
                                    <div class="at-item-content">
                                        
                                        <a-date-picker
                                            v-model="el.start"
                                            class="date-picker full-with"
                                            format="YYYY-MM-DD"
                                            placeholder="选择时间"
                                        />
                                    </div>
                                </div>
                                
                                <div class="at-item-sg">
                                    <div class="at-item-title">排序序号</div>
                                    <div class="at-item-content">
                                        
                                        <at-input class="full-with" v-model="el.sort"/>
                                    
                                    </div>
                                </div>
                            </div>
                            
                            <div class="at-item item-two">
                                
                                <div class="at-item-sg">
                                    
                                    <div class="at-item-title">{{ typeName }}方式</div>
                                    
                                    <div class="at-item-title">
                                        <a-select v-model="el.type" class="full-with at-select">
                                            <a-select-option value="online">线上</a-select-option>
                                            <a-select-option value="offline">线下</a-select-option>
                                        </a-select>
                                    </div>
                                </div>
                                
                                <div class="at-item-sg">
                                    
                                    <div class="at-item-title">{{ groupName }}价格（选填）</div>
                                    
                                    <div class="at-item-content">
                                        <at-input :unit="$app.payment.symbol" v-model="el.price" class="full-with"/>
                                    </div>
                                </div>
                            
                            </div>
                            
                            <template v-if="$app.model !== 'person' ">
                                <div class="at-item item-two">
                                    
                                    <div class="at-item-sg">
                                        
                                        <div class="at-item-title">报名开启支付</div>
                                        
                                        <div class="at-item-title">
                                            <a-select v-model="el.pay_enabled" class="full-with at-select"
                                                      @change=" el.pay_amount = '0.00' ">
                                                <a-select-option :value="1">开启</a-select-option>
                                                <a-select-option :value="0">关闭</a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                    
                                    <div class="at-item-sg">
                                        
                                        <div class="at-item-title">支付金额</div>
                                        
                                        <div class="at-item-content">
                                            <at-input :unit="$app.payment.symbol" :disabled=" !el.pay_enabled " v-model="el.pay_amount"
                                                      class="full-with"/>
                                        </div>
                                    </div>
                                
                                </div>
                                
                                
<!--                                <div class="at-item item-two" v-if="shareEnabled">
                                    
                                    <div class="at-item-sg">
                                        
                                        <div class="at-item-title">开启分销</div>
                                        
                                        <div class="at-item-title">
                                            <a-select v-model="el.share_enabled"
                                                      class="full-with at-select"
                                                      @change=" shareEnabledChanged ">
                                                <a-select-option :value="1">开启</a-select-option>
                                                <a-select-option :value="0">关闭</a-select-option>
                                            </a-select>
                                        </div>
                                    </div>
                                    
                                    <div class="at-item-sg">
                                        
                                        <div class="at-item-title">分销比例</div>
                                        
                                        <div class="at-item-content">
                                            <at-input :disabled=" !el.share_enabled " v-model="el.share_scale"
                                                      class="full-with">
                                                <div class="scale-unit">%</div>
                                            </at-input>
                                        
                                        </div>
                                    </div>
                                
                                </div>
                                
                                <div v-if="!shareEnabled" class="share-tips">
                                    如需开通分销功能，请联系客服进行机构认证！
                                </div>-->
                            </template>
                            
                            <div class="at-item">
                                <div class="at-item-title">地点（选填）</div>
                                <div class="at-item-content">
                                    <at-input v-model="el.address" class="full-with"/>
                                </div>
                            </div>
                            
                            <div class="at-item">
                                <div class="at-item-title">{{ groupName }}详情</div>
                                <div class="at-item-content">
                                    <a-textarea v-model="el.summary" class="sd-textarea"/>
                                    
                                    <div class="content-container">
                                        <div class="content-img">
                                            <upload-image v-model="el.content_images" multiple/>
                                        </div>
                                        <div class="thumb-tips">
                                            图片格式必须为：png,bmp,jpeg,jpg；不可大于2M；<br>
                                            建议使用png格式图片，以保持最佳效果；<br>
                                            建议图片宽度为980px
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-collapse-panel>
                        
                        <a-collapse-panel v-if="$app.model !== 'person' " :header="`${groupName}营销`" key="2"
                                          forceRender>
                            
                            <div class="at-item item-two">
                                <div class="at-item-sg">
                                    <div class="at-item-title">首次购买优惠价格</div>
                                    
                                    <div class="at-item-content">
                                        
                                        <div class="at-item-content">
                                            <at-input v-model="el.first_buy_amount"
                                                      class="full-with"/>
                                        </div>
                                    
                                    
                                    </div>
                                </div>
                                
                                <div class="at-item-sg">
                                    <div class="at-item-title">首次购买优惠限时（分钟）</div>
                                    
                                    <div class="at-item-content">
                                        
                                        <div class="at-item-content">
                                            <at-input v-model="el.first_buy_time"
                                                      class="full-with"/>
                                        </div>
                                    
                                    
                                    </div>
                                </div>
                            </div>
                            
                            <div class="thumb-tips mt-4">
                                填写首次购买优惠价后，用户第一次进入该产品，在设定限定时间内支付，可按该优惠价进行购买
                            </div>
                        
                        
                        </a-collapse-panel>
                        
                        <a-collapse-panel v-if="$app.model !== 'person' " header="购买后功能" key="3" forceRender>
                            
                            <div class="at-item">
                                <div class="at-item-sg">
                                    
                                    <div class="at-item-title">赠送衣橱会员</div>
                                    
                                    <div class="at-item-title">
                                        <a-select v-model="el.give_wd_vip" class="full-with at-select">
                                            <a-select-option :value="1">赠送</a-select-option>
                                            <a-select-option :value="0">不赠送</a-select-option>
                                        </a-select>
                                    </div>
                                </div>
                            
                            </div>
                            <div class="at-item item-two">
                                
                                <div class="at-item-sg">
                                    
                                    <div class="at-item-title">等级变更</div>
                                    
                                    <div class="at-item-content">
                                        
                                        <a-select v-model="el.give_member_level" class="full-with at-select">
                                            <a-select-option :value="0">不变更</a-select-option>
                                            <template v-for="(level,key) in memberLevels">
                                                <a-select-option :value="level.id" :key="key" v-if="true">
                                                    <span>{{ level.name }}</span>
                                                </a-select-option>
                                            </template>
                                        </a-select>
                                    
                                    </div>
                                </div>
                                
                                <div class="at-item-sg">
                                    
                                    <div class="at-item-title">会员有效期限</div>
                                    
                                    <div class="at-item-content">
                                        
                                        <a-select v-model="el.give_member_level_days" class="full-with at-select">
                                            <template v-for="(md,key) in memberLevelDays">
                                                <a-select-option :value="md.day" :key="key" v-if="true">
                                                    <span>{{ md.name }}</span>
                                                </a-select-option>
                                            </template>
                                        </a-select>
                                    
                                    </div>
                                </div>
                            </div>
                            
                            <div class="at-item">
                                <div class="at-item-title">给予权限</div>
                                
                                <div class="at-item-content">
                                    
                                    <a-select mode="tags" size="large" v-model="el.give_permissions"
                                              class="full-with at-select" placeholder="不给予权限" @change="updateView">
                                        <template v-for="(name,key) in memberPermissions">
                                            <a-select-option :value="key" :key="key" v-if="true">{{
                                                    name
                                                }}
                                            </a-select-option>
                                        </template>
                                    </a-select>
                                
                                </div>
                            </div>
                        
                        </a-collapse-panel>
                    
                    
                    </a-collapse>
                    
                    
                    <div class="sc-bk"></div>
                
                </perfect-scrollbar>
                
                <div class="sd-footer">
                    <div class="footer-btns">
                        <template v-if="item && item.id">
                            
                            <button @click="save" class="btn">保存</button>
                            
                            <template v-if="item.status === 0 ">
                                <button @click="stock(1)" class="btn btn-white">上架</button>
                            </template>
                            <template v-else-if="item.status === 1">
                                <button @click="stock(0)" class="btn btn-white">下架</button>
                            </template>
                            
                            
                            <button @click="deleteItem" class="btn btn-white">删除</button>
                        
                        </template>
                        <template v-else>
                            
                            <button @click="save" class="btn">创建</button>
                        
                        </template>
                    
                    </div>
                </div>
            
            
            </div>
        </a-drawer>
    </div>
</template>

<script>
import AtInput from "../../../components/AtInput";
import UploadImage from "../../../components/UploadImage";
import api from '../../../repo/api';

export default {
    name: "Detail",
    components: {UploadImage, AtInput},
    props: {
        item: Object,
        active: Boolean,
        group: String,
        shareEnabled: Number
    },
    model: {
        prop: 'active',
        event: 'change'
    },
    watch: {
        active() {
            if (this.active) {
                
                let item = this.item || this.elTpl
                
                this.el = JSON.parse(JSON.stringify(item))
                
                if (!this.el.give_permissions) this.el.give_permissions = []
                
                if (!this.el.content_images) this.el.content_images = [];
                
                this.el.give_member_level = +this.el.give_member_level;
                
                this.collapseActiveKey = '1';
            }
        },
    },
    data() {
        return {
            el: {},
            collapseActiveKey: '1',
            elTpl: {
                group: '',
                title: '',
                sub_title: '',
                pay_amount: '0.00',
                pay_enabled: 0,
                share_enabled: 0,
                share_scale: 0,
                thumb: '',
                start: '',
                type: 'online',
                price: '',
                address: '',
                summary: '',
                content_images: [],
                give_wd_vip: '',
                give_member_level: 0,
                give_member_level_days: '',
                give_permissions: [],
                first_buy_amount: '',
                first_buy_time: '',
            },
            groups: {
                activity: '活动',
                serve: '服务',
                courses: '课程',
            },
            types: {
                activity: '活动',
                serve: '服务',
                courses: '授课',
            },
            memberLevelDays: [
                {
                    name: "7天",
                    day: 7
                }, {
                    name: "半个月（15天）",
                    day: 15,
                }, {
                    name: "1个月（30天）",
                    day: 30
                }, {
                    name: "3个月（90天）",
                    day: 90
                }, {
                    name: "半年（180天）",
                    day: 180
                }, {
                    name: "1年（365天）",
                    day: 365
                }, {
                    name: "长期",
                    day: -1
                }
            ],
            memberLevels: [],
            memberPermissions: {},
        }
    },
    mounted() {
        this.getPermissionsAndLevels()
    },
    computed: {
        groupName() {
            return this.groups[this.group]
        },
        typeName() {
            return this.types[this.group]
        }
    },
    methods: {
        updateView() {
            this.$forceUpdate()
        },
        getPermissionsAndLevels() {
            api.get('/ast-app/permissions-and-levels', (res) => {
                
                
                this.memberLevels = res.data.levels;
                this.memberPermissions = res.data.permissions;
                
                console.log(this.memberLevels, this.memberPermissions);
                
            })
        },
        close() {
            this.$emit('change', false);
        },
        deleteItem() {
            this.confirm('确定要删除？', '此操作不可逆。').then(() => {
                
                this.$loading.show();
                
                api.post('/course/delete', {id: this.item.id}, (data) => {
                    this.success(data)
                })
                
            })
        },
        stock(status) {
            
            let title = status ? "上架" : '下架';
            
            let content = ''
            
            if (status) {
                content = '上架后将在小程序端上显示.'
            } else {
                content = '下架后将不会在小程序端上显示.'
            }
            
            this.confirm('确定' + title, content).then(() => {
                
                this.$loading.show();
                
                api.post('/course/stock', {status, id: this.item.id}, (data) => {
                    this.success(data)
                })
                
            })
        },
        confirm(title, content) {
            return new Promise((resolve) => {
                this.$confirm({
                    title: title,
                    content: content,
                    onOk() {
                        resolve()
                    },
                });
            })
        },
        success(data) {
            this.$loading.hide();
            
            this.$message.data(data)
            
            if (data.code === 0) {
                this.close()
                
                this.$emit('success')
            }
        },
        save() {
            
            this.$loading.show();
            
            if (!this.el.group) {
                this.el.group = this.group
            }
            
            api.post('/course/sync', this.el, (data) => {
                
                this.success(data)
            })
        },
        shareEnabledChanged(value) {
            
            if (!value) {
                
                this.el.share_scale = '0.00'
                
                return
            }
            
            this.el.pay_enabled = 1
        }
    }
}
</script>

<style lang="less" scoped>
.at-item {
    margin-top: 32px;
    
    &:first-child {
        margin-top: 0;
    }
}

.sd-collapse {
    margin-top: 32px;
}

.sd-footer {
    height: 96px;
}

.content-img {
    height: auto !important;
}

.at-item-title {
    margin-bottom: 16px;
}

.sd-body {
    flex: 1;
    padding-right: 32px;
}

.date-picker {
    /deep/ .ant-input {
        height: 40px;
        border-radius: 0;
    }
    
    /deep/ .ant-calendar-range-picker-separator {
        line-height: 30px;
    }
}

.at-title-edit, .at-title-create {
    width: 96px;
    height: 64px;
    background: url("/assets/icons/edit-item.png") no-repeat;
    background-size: 96px 64px;
}

.at-title-create {
    background-image: url("/assets/icons/create-item.png")
}

.at-title {
    height: 64px;
}

.serve-detail {
    /deep/ .ant-drawer-header-no-title {
        /*display: none;*/
    }
    
    /deep/ .ant-drawer-body {
        padding: 0;
    }
}

.wrapper {
    height: 100vh;
    background: #FFFFFF;
    padding: 24px 0 0 32px;
    display: flex;
    flex-direction: column;
}

.thumb-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.thumb-tips {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
}

.thumb-img {
    width: 104px;
    height: 104px;
    background-color: #999999;
    margin-right: 16px;
    line-height: 0;
}

.footer-btns {
    margin-top: 24px;
    
    .btn {
        margin-right: 16px;
        width: 80px;
    }
}

.item-two {
    display: flex;
    justify-content: left;
    
    .at-item-sg {
        width: 50%;
        
        &:first-child {
            padding-right: 20px;
        }
        
        &:last-child {
            padding-left: 20px;
        }
    }
}

.mt-4 {
    margin-top: 8px;
}

.at-select {
    /deep/ .ant-select-selection--single {
        height: 40px;
        border-radius: 0;
    }
    
    /deep/ .ant-select-selection__rendered {
        line-height: 40px;
    }
}

.at-item-content {
    
    position: relative;
    
    .input-tips {
        position: absolute;
        right: 5px;
        top: 18px;
        font-size: 12px;
        color: #a4a4a4;
    }
}

.sd-textarea {
    height: 96px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 0;
    margin-bottom: 16px;
}

.sc-bk {
    height: 60px;
}

.scale-unit {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-left: 1px solid #EEEEEE;
    user-select: none;
}

.share-tips {
    color: #FBB03B;
}

</style>